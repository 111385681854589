import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);


export default {
  state: {
    auth_id: null,
    auth_role: null,
    token:  null,
    name: '',
    islogged: false,
  },
  actions: {
    F_TOKEN:({commit}, payload) => {

      function b64DecodeUnicode(str) {
        return decodeURIComponent(
          atob(str).replace(/(.)/g, function (m, p) {
            var code = p.charCodeAt(0).toString(16).toUpperCase();
            if (code.length < 2) {
              code = "0" + code;
            }
            return "%" + code;
          })
        );
      }
      
      function base64_url_decode(str) {
        var output = str.replace(/-/g, "+").replace(/_/g, "/");
        switch (output.length % 4) {
          case 0:
            break;
          case 2:
            output += "==";
            break;
          case 3:
            output += "=";
            break;
          default:
            throw "Illegal base64url string!";
        }
      
        try {
          return b64DecodeUnicode(output);
        } catch (err) {
          return atob(output);
        }
      }


      return new Promise((resolve) => {       
        localStorage.setItem('Token', payload.token);
        localStorage.setItem('UserId', JSON.parse(base64_url_decode(payload.token.split('.')[1])).UserId);
        localStorage.setItem('RoleId', JSON.parse(base64_url_decode(payload.token.split('.')[1])).RoleId);
        //localStorage.setItem('Name', JSON.parse(atob(payload.token.split('.')[1])).Name);
        localStorage.setItem('UserName', JSON.parse(base64_url_decode(payload.token.split('.')[1])).UserName);
        localStorage.setItem('Email', JSON.parse(base64_url_decode(payload.token.split('.')[1])).Email);
        localStorage.setItem('AirportId', JSON.parse(base64_url_decode(payload.token.split('.')[1])).AirportId);
        localStorage.setItem('BSapp', JSON.parse(base64_url_decode(payload.token.split('.')[1])).BSapp);
        localStorage.setItem('RWYapp', JSON.parse(base64_url_decode(payload.token.split('.')[1])).RWYapp);
        localStorage.setItem('WLapp', JSON.parse(base64_url_decode(payload.token.split('.')[1])).WLapp);
        localStorage.setItem('PAASapp', JSON.parse(base64_url_decode(payload.token.split('.')[1])).PAASapp);
        localStorage.setItem('IcaoName', JSON.parse(base64_url_decode(payload.token.split('.')[1])).IcaoName);
        localStorage.setItem('Configuration', JSON.parse(base64_url_decode(payload.token.split('.')[1])).ConfigurationId);
        localStorage.setItem('validation', 'true');
        localStorage.setItem('activeItem', "0"); //ucitavanje active menija

        localStorage.setItem('Type_of_report', "1"); //default type of report to load in airport map 
        localStorage.setItem('AirportMap_changeAirport', ""); //clicked airport button on airport map for superadmin
        localStorage.setItem('User_Permission', JSON.parse(base64_url_decode(payload.token.split('.')[1])).Permission);

        //console.log(atob(decodeURIComponent(localStorage.getItem('Token').split(".")[1])));
        //localStorage.setItem('EmailNotification', JSON.parse(atob(payload.token.split('.')[1])).EmailNotification);

        //for PAAS users
        localStorage.setItem('Paas_RoleId', JSON.parse(base64_url_decode(payload.token.split('.')[1])).Paas_RoleId);
        localStorage.setItem('Paas_TypeId', JSON.parse(base64_url_decode(payload.token.split('.')[1])).Paas_TypeId);

        localStorage.setItem('GPS', JSON.parse(base64_url_decode(payload.token.split('.')[1])).GPS);
        //JSON.parse(atob(payload.token.split('.')[1]))

      });
    },
  }
};